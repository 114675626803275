.ProductListContainer {
    padding-left: 5px;
    text-align: center;
    padding-right: 5px;
}

.HideSalesButton {
    align-content: center;
    background: #40a1f1;
    padding: 7px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    left: 95vh;
    top: 50vh;
    cursor: pointer;
    color: aliceblue;
    font-size: 16px;
    font-weight: bold;
}

.HideSalesButton:hover {
    background: #378cd1;
}

.hideIcon{
    position: relative;
    bottom: 3px;
    left: 3px;
}