.ProductTypesContainer {
    display: grid;
}

.RemoveLinkColor {
    color: rgb(53, 53, 53) !important;
}

.ProductTypeItem {
    padding: 15px;
    cursor: pointer;
    margin-top: 5px;
    color: rgb(53, 53, 53) !important;
    font-weight: 500;
    border-bottom: 2px solid #f6f6f6;
}

.ProductTypeItem:hover {
    color: black !important;
    font-weight: bold;
    border-bottom: 2px solid #f6f6f6;
    background-color: rgb(248, 248, 236);
}

.ProductTypeItemActive {
    margin-top: 5px;
    color: black !important;
    font-weight: bold;
    border-bottom: 2px solid #f6f6f6;
    background-color: rgb(248, 248, 236);
}