.LabelSection {
    display: grid;
}

.LabelSection .Property {
    font-weight: bold;
    display: initial;
}

.OptionProductCol {
    cursor: pointer;
    height: 50px
}

.ProductOptionIcon {
    width: 30px;
    padding-top: 3px;
}

.OptionProductCol:hover .ProductOptionIcon {
    width: 35px;
    font-size: 17px;
}

.ProductCart {
    text-align: center;
}

.ProductCart .ant-card-body {
    padding: 5px;
}

.ButtonBadge {
    color: #f6f6f6 !important;
}

.moreOptions {
    background: #f6f6f6;
    padding: 6px;
    border: 1px solid gainsboro;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.MoreOptionsButton {
    background: #f6f6f6;
    border: 1px solid gainsboro;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.MoreOptionsButton:hover {
    background: #b9b9b9;
}

.AddCartModalLoading {
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid rgba(122, 119, 103, 0.651);
    border-radius: 5px;
}

.moreOptions:hover {
    background: #d8d8d8;
    border: 1px solid rgb(165, 165, 165);
}

.moreIcons {
    position: relative;
    bottom: 3px;
    right: 3px;
}


.NewLabel {
    background: red;
    color: white;
    text-transform: uppercase;
    position: absolute;
    top: 6px;
    left: 1px;
    z-index: 2;
    transform: rotate(-45deg);
}

.NewLabel:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotateX(180deg);
    border-width: 23px 22px 18px 0px;
    border-color: red transparent transparent transparent;
    position: absolute;
    left: 31px;
    top: -19px;
}

.NewLabel:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    transform: rotateZ(180deg);
    border-width: 23px 22px 18px 0px;
    border-color: red transparent transparent transparent;
    position: absolute;
    left: -22px;
    top: -19px;

}