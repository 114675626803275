.TotalLabel{
    padding: 5px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    width: 100%;
    border: 1px solid #757464;
    border-radius: 5px;
}

.loadingText{
    text-align: -webkit-center;
}