.App {
    height: 100vh;
    background: #e4e3e3;
    font-weight: 500;
    /* font-family: 'Abhaya Libre'!important; */
}

.AppContent {
    overflow-y: auto;
    height: 90vh;
}

.center {
    text-align: -webkit-center;
}

.appLogo {
    height: 15vmin;
    pointer-events: none;
    mix-blend-mode: multiply;
}

.ButtonPrimaryColor:hover {
    opacity: 0.9 !important;
}

.ButtonPrimaryTextColor:hover {
    opacity: 1 !important;
}

.ButtonDangerColor {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #af4227;
    color: #fefefe;
    background: #e7725d;
}

.ButtonDangerColor:hover {
    background: #9c594d;
}

.DrawerTitle {
    margin: 0;
    color: rgb(131 107 67);
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
}

.AddIcon {
    font-size: 30px;
    padding-top: 30%;
    cursor: pointer;
    color: rgb(133, 133, 133) !important;
}

.AddIcon:hover {
    font-size: 32px;
    cursor: pointer;
    color: rgb(65, 65, 65) !important;
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}