.Container {
    overflow-y: auto;
    text-align: center;
    padding: 30px;
}

.ExtraActions {    
    color: rgb(78 68 68);
    border: 2px solid rgba(245, 245, 220, 0);;
}

.ExtraActions:hover {    
    color: rgb(27, 26, 26);
    border-bottom: 2px solid rgb(44, 47, 47);
}
