.SaleContainer {
    background-color: #adafaf;
    height: 93vh;
    padding: 10px;
    overflow-y: auto;
}

.SubTitle {
    color: 484848;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.addSaleButton{
   font-size: 26px;
   cursor: pointer;
}

.SearchInput{
    background: rgb(255 255 255 / 0%);
    border: hidden;
}

.SearchInput:focus { 
    outline: none; 
}

.previewProductModal .ant-modal-content {
    width: 400px;
}
