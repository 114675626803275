.StoreListContainer {
    padding: 10px;
    overflow: auto;
    height: 93vh;
    text-align: center;
}

.saleDetailPanel{
    background: #d6d6d6;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border-left: 8px solid #7aa7a8;
}

.salePanel{
    background: #d6d6d6;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    border-left: 8px solid #5bb3a0;
}

.saleSubTitle{
    border-bottom: 1px solid #aaa0a0;
    font-weight: bold;   
}